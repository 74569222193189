<template>
  <div>
    <div class="side_header">
      <SideBarInnerHeader :title="'New Chat'" @closeChat="closeChat" />
    </div>
    <div class="side_wrapper d-flex align-items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          fill="#bbb"
          d="M15.009 13.805h-.636l-.22-.219a5.184 5.184 0 0 0 1.256-3.386 5.207 5.207 0 1 0-5.207 5.208 5.183 5.183 0 0 0 3.385-1.255l.221.22v.635l4.004 3.999 1.194-1.195-3.997-4.007zm-4.808 0a3.605 3.605 0 1 1 0-7.21 3.605 3.605 0 0 1 0 7.21z"
        ></path>
      </svg>
      <input
        type="text"
        id="search"
        v-model="user_search_key"
        v-on:keyup="user_search_key_set"
        class="search_factory"
        placeholder=" Search Factory for New Chat"
      />
    </div>
    <div class="users_newchat_wrapper">
      <ul id="users_newchat_list">
        <Convertion
          :setConvertions="usersResultData"
          :room="null"
          @switchConvertion="newChatStart"
          :lang="lang"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import SideBarInnerHeader from "../components/SideBarInnerHeader.vue";
import Convertion from "../components/Convertion.vue";
export default {
  props: ["usersResult", "lang"],
  components: {
    SideBarInnerHeader,
    Convertion,
  },
  data: function() {
    return {
      data: null,
      usersResultData: [],
      user_search_key: null,
    };
  },
  watch: {
    usersResult: function(newVal, oldVal) {
      this.usersResultData = newVal;
    },
  },
  methods: {
    newChatStart: function(item) {
      this.$emit("createNewChat", item);
      this.closeChat();
    },
    closeChat: function() {
      this.$emit("closeChat");
    },
    user_search_key_set: function() {
      this.$emit("uSKSet", this.user_search_key.trim());
    },
    clearValue: function() {
      this.user_search_key = "";
    },
  },
};
</script>
