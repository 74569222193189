<template>
  <header class="chat-header">
    <div class="mobileMenu">
      <button v-on:click="mobileToggle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          aria-hidden="true"
          role="img"
          width="20"
          height="22.86"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 448 512"
        >
          <path
            d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
          />
        </svg>
      </button>
    </div>
    <h1>
      <a href="https://gowawe.com"
        ><img
          src="https://gowawe.com/img/gowawe.png"
          class="img-fluid"
          width="200"
      /></a>
    </h1>
  </header>
</template>

<script>
export default {
  props: [],
  data: function() {
    return {
      data: null,
    };
  },
  methods: {
    mobileToggle: function() {
      // chatListActive
      const body = document.getElementsByTagName("body")[0];
      if (body.classList.contains("chatListActive")) {
        body.classList.remove("chatListActive");
        this.$emit("closeChat");
      } else {
        body.classList.add("chatListActive");
      }
    },
  },
};
</script>
