<template>
  <div v-if="setConvertions.length > 0">
    <li
      v-for="(c, index) in setConvertions"
      :key="index"
      :id="c.convertion_id"
      v-show="c.deleted ? c.deleted == false : true"
      v-bind:class="{
        active:
          currentConvertion &&
          c.convertion_id == currentConvertion.convertion_id,
      }"
    >
      <div
        class="sl_item d-flex flex-nowrap align-items-start setRoom"
        v-on:click="newChatStartEmit(c)"
      >
        <img
          :src="c.logo ? c.logo : 'https://via.placeholder.com/50'"
          class="list_logo"
        />
        <div class="mediaContent">
          <div class="d-flex flex-row justify-content-between">
            <h5 class="font-weight-bold">
              {{ c.name }}
            </h5>
            <span class="last_message_time">{{
              moment(c.time)
                .locale(lang)
                .format("DD-MM-YYYY")
            }}</span>
          </div>
          <div class="d-flex flex-row">
            <span class="start_to_new_chat d-flex align-items-center">{{
              c.last_message ? c.last_message : "Start to new chat!"
            }}</span>
          </div>
        </div>
      </div>
    </li>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["setConvertions", "currentConvertion", "lang"],
  data: function() {
    return {
      data: null,
      moment: moment,
    };
  },
  watch: {
    setConvertions: function(newVal, oldVal) {
      this.setConvertions = newVal;
    },
    currentConvertion: function(newVal, oldVal) {
      this.currentConvertion = newVal;
    },
  },
  methods: {
    newChatStartEmit: function(item) {
      this.$emit("switchConvertion", item);
    },
  },
  mounted() {
    //console.log(this.setConvertions['61dea768d8f8d656942c6d92'].convertion_id + ' ' + this.room);
  },
};
</script>
