<template>
  <div
    class="d-flex flex-wrap w-100 align-items-center justify-content-between"
  >
    <div class="user_image">
      <a
        class="datatab"
        data-tab="tab3"
        href="javascript:void(0);"
        v-on:click="openSide(3)"
      >
        <img
          :src="user_info ? user_info.logo : 'https://via.placeholder.com/50'"
          class="img-fluid"
        />
      </a>
    </div>
    <div class="user_buttons">
      <button
        id="newChat"
        class="datatab"
        data-tab="tab1"
        v-on:click="openSide(1)"
      >
        <svg
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path
            fill="gray"
            d="M19.005 3.175H4.674C3.642 3.175 3 3.789 3 4.821V21.02l3.544-3.514h12.461c1.033 0 2.064-1.06 2.064-2.093V4.821c-.001-1.032-1.032-1.646-2.064-1.646zm-4.989 9.869H7.041V11.1h6.975v1.944zm3-4H7.041V7.1h9.975v1.944z"
          ></path>
        </svg>
      </button>
      <button
        id="chatConfig"
        class="datatab"
        data-tab="tab2"
        v-on:click="openSide(2)"
      >
        <svg
          role="button"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path
            fill="gray"
            d="M12 7a2 2 0 1 0-.001-4.001A2 2 0 0 0 12 7zm0 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 9zm0 6a2 2 0 1 0-.001 3.999A2 2 0 0 0 12 15z"
          ></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBarHeader",
  props: ["user_info"],
  data: function() {
    return {
      data: null,
    };
  },
  methods: {
    openSide: function(id) {
      this.$emit("openSide", id);
    },
  },
};
</script>
