<template>
  <div id="chat-form" class="d-flex align-items-center">
    <button
      class="attachment_add"
      v-on:click="openAttachment()"
      :disabled="sendMessageDeActive"
    >
      <svg
        version="1.1"
        id="Icons"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 32 32"
        style="enable-background:new 0 0 32 32;"
        xml:space="preserve"
      >
        <path
          class="st0"
          d="M24.8,13.8L16,5c-2.7-2.7-7-2.7-9.6,0l0,0c-2.7,2.7-2.7,7,0,9.6l12.8,12.8c1.8,1.8,4.6,1.8,6.4,0l0,0
        c1.8-1.8,1.8-4.6,0-6.4L14.4,9.8c-0.9-0.9-2.3-0.9-3.2,0l0,0c-0.9,0.9-0.9,2.3,0,3.2l7.2,7.2"
        />
      </svg>
    </button>
    <SendMessageInput
      ref="SendMessageInput"
      :sendMessageDeActive="sendMessageDeActive"
      @sendMessageKey="sendMessageKey"
    />
    <SendMessageButton
      @sendMessageKey="sendMessageKey"
      :sendMessageDeActive="sendMessageDeActive"
    />
  </div>
</template>

<script>
import SendMessageInput from "./SendMessageInput.vue";
import SendMessageButton from "./SendMessageButton.vue";

export default {
  props: ["sendMessageDeActive"],
  data: function() {
    return {
      message: "",
    };
  },
  components: {
    SendMessageInput,
    SendMessageButton,
  },
  methods: {
    sendMessageKey: function() {
      this.$emit("sendMessageKeyChat", this.$refs.SendMessageInput.message);
    },
    clearText: function() {
      this.$refs.SendMessageInput.message = "";
    },
    openAttachment: function() {
      this.$emit("openAttachment");
    },
  },
};
</script>

<style type="text/css">
.st0 {
  fill: none;
  stroke: #858585;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
</style>
