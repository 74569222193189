<template>
  <div>
    <div class="side_header">
      <SideBarInnerHeader :title="'Profile'" @closeChat="closeChat" />
    </div>
    <div class="side_wrapper d-flex flex-column align-items-center">
      <div
        class="config_header d-flex flex-wrap flex-column w-100 align-items-center justify-content-center"
      >
        <img
          :src="user_info ? user_info.logo : 'https://via.placeholder.com/100'"
          class="img-fluid"
        />
        <h5>{{ user_info ? user_info.name : "" }}</h5>
        <h6 class="list_of_item_profile  d-flex align-items-center" id="phone">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            width="23"
            height="23"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
            class="mr-2"
          >
            <path
              d="M17.707 12.293a.999.999 0 0 0-1.414 0l-1.594 1.594c-.739-.22-2.118-.72-2.992-1.594s-1.374-2.253-1.594-2.992l1.594-1.594a.999.999 0 0 0 0-1.414l-4-4a.999.999 0 0 0-1.414 0L3.581 5.005c-.38.38-.594.902-.586 1.435c.023 1.424.4 6.37 4.298 10.268s8.844 4.274 10.269 4.298h.028c.528 0 1.027-.208 1.405-.586l2.712-2.712a.999.999 0 0 0 0-1.414l-4-4.001zm-.127 6.712c-1.248-.021-5.518-.356-8.873-3.712c-3.366-3.366-3.692-7.651-3.712-8.874L7 4.414L9.586 7L8.293 8.293a1 1 0 0 0-.272.912c.024.115.611 2.842 2.271 4.502s4.387 2.247 4.502 2.271a.991.991 0 0 0 .912-.271L17 14.414L19.586 17l-2.006 2.005z"
              fill="currentColor"
            />
          </svg>
          <span>{{ user_info ? user_info.phone : "" }}</span>
        </h6>
        <h6 class="list_of_item_profile d-flex align-items-center" id="email">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            width="20"
            height="20"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
            class="mr-2"
          >
            <g fill="none">
              <path
                d="M2 6a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2 8l7.501 6.001a4 4 0 0 0 4.998 0L22 8"
                stroke="currentColor"
                stroke-width="2"
                stroke-linejoin="round"
              />
            </g>
          </svg>
          <span>{{ user_info ? user_info.email : "" }}</span>
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import SideBarInnerHeader from "../components/SideBarInnerHeader.vue";
export default {
  props: ["user_info"],
  components: {
    SideBarInnerHeader,
  },
  data: function() {
    return {
      data: null,
    };
  },
  methods: {
    closeChat: function() {
      this.$emit("closeChat");
    },
  },
};
</script>
