<template>
  <div class="image_scene">
    <img :src="ImageSceneURL" class="img-fluid" alt="" />
  </div>
</template>

<script>
export default {
  props: ["ImageSceneURL"],
  data: function() {
    return {
      data: null,
    };
  },
  methods: {},
};
</script>

<style scoped>
.image_scene {
  position: relative;
  width: 100%;
  height: calc(100% - 100px);
  padding: 2em;
  text-align: center;
  margin: auto;
}
.image_scene img {
  max-height: 100%;
}
</style>
