<template>
  <div class="d-flex w-100 justify-content-between">
    <div class="ch-part">
      <div class="logo_name d-flex align-items-center">
        <img :src="currentConvertion ? currentConvertion.logo : null" />
        <h5>{{ currentConvertion ? currentConvertion.name : null }}</h5>
      </div>
    </div>

    <div class="ch-part">
      <div class="ch-Button">
        <button class="searchOnMessage">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path
              d="M15.009 13.805h-.636l-.22-.219a5.184 5.184 0 0 0 1.256-3.386 5.207 5.207 0 1 0-5.207 5.208 5.183 5.183 0 0 0 3.385-1.255l.221.22v.635l4.004 3.999 1.194-1.195-3.997-4.007zm-4.808 0a3.605 3.605 0 1 1 0-7.21 3.605 3.605 0 0 1 0 7.21z"
            ></path>
          </svg>
        </button>
      </div>
      <div class="ch-Button">
        <button class="dropdownMenu" v-on:click="chConfig = !chConfig">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 10c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2zm6 0c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2zM6 10c-1.1 0-2 .9-2 2s.9 2 2 2s2-.9 2-2s-.9-2-2-2z"
            ></path>
          </svg>
        </button>
        <div class="ch-Menu" v-show="chConfig">
          <ul>
            <li>
              <button class="deleteChat" v-on:click="deleteChat">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  aria-hidden="true"
                  role="img"
                  width="20"
                  height="20"
                  preserveAspectRatio="xMidYMid meet"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M6 7H5v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V7H6zm10.618-3L15 2H9L7.382 4H3v2h18V4z"
                  ></path>
                </svg>
                Delete Chat
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["currentConvertion"],
  data: function() {
    return {
      data: null,
      chConfig: false,
    };
  },
  methods: {
    deleteChat: function() {
      this.$emit("deleteChat");
      this.chConfig = false;
    },
  },
  mounted() {
    // console.log(this.currentConvertion);
  },
};
</script>
