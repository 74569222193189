<template>
  <div>
    <div class="side_header">
      <SideBarInnerHeader :title="'Config'" @closeChat="closeChat" />
    </div>
    <div class="side_wrapper d-flex flex-column align-items-center">
      <ul class="config_list">
        <li>
          <div
            class="content_config d-flex align-items-center justify-content-between"
          >
            <label>Notification Sound</label>
            <label class="switch">
              <input
                type="checkbox"
                checked
                id="NotifySoundConfig"
                v-model="soundmode"
                v-on:change="changeMode('sound')"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </li>
        <li>
          <div
            class="content_config d-flex align-items-center justify-content-between"
          >
            <label>Dark Mode</label>
            <label class="switch">
              <input
                type="checkbox"
                id="DarkModeConfig"
                v-model="darkmode"
                v-on:change="changeMode('theme')"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import SideBarInnerHeader from "../components/SideBarInnerHeader.vue";
export default {
  props: [],
  components: { SideBarInnerHeader },
  data: function() {
    return {
      data: null,
      darkmode: false,
      soundmode: false,
    };
  },
  methods: {
    closeChat: function() {
      this.$emit("closeChat");
    },
    modeControl: function() {
      const body = document.getElementsByTagName("body")[0];
      if (this.darkmode) {
        body.classList.add("dark");
      } else {
        body.classList.remove("dark");
      }
    },
    changeMode: function(type) {
      if (type == "theme") {
        this.modeControl();
      }

      if (type == "sound") {
        this.soundmode = !this.soundmode;
      }

      if (typeof Storage !== "undefined") {
        localStorage.setItem(
          "storageObj",
          JSON.stringify({
            soundmode: this.soundmode,
            darkmode: this.darkmode,
          })
        );
      }
    },
  },
  created() {
    if (typeof Storage !== "undefined") {
      const st = localStorage.getItem("storageObj");
      if (st) {
        this.soundmode = JSON.parse(st).soundmode;
        this.darkmode = JSON.parse(st).darkmode;
        this.modeControl();
      }
    }
  },
};
</script>
