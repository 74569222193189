<template>
  <div id="startup">
    <svg
      class="spinner-container"
      width="65px"
      height="65px"
      viewBox="0 0 52 52"
    >
      <circle
        class="path"
        cx="26px"
        cy="26px"
        r="20px"
        fill="none"
        stroke-width="4px"
      />
    </svg>
  </div>
</template>

<script>
export default {
  props: [],
  data: function() {
    return {
      data: null,
    };
  },
};
</script>
