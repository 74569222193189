<template>
  <input
    :disabled="sendMessageDeActive"
    type="text"
    placeholder="Write somethings"
    autocomplete="off"
    v-model="message"
    v-on:keyup.enter="sendMessageKey()"
  />
</template>

<script>
export default {
  props: ["sendMessageDeActive"],
  data: function() {
    return {
      message: "",
    };
  },
  methods: {
    sendMessageKey: function() {
      this.$emit("sendMessageKey");
    },
  },
};
</script>
