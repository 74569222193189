<template>
  <h5 class="d-flex">
    <button class="closeNewChat" @click="closeChat">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        role="img"
        width="18"
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 344 384"
        class="mr-2"
      >
        <path
          d="M341 171v42H82l119 120l-30 30L0 192L171 21l30 30L82 171h259z"
          fill="white"
        />
      </svg>
    </button>
    <span>{{ title }}</span>
  </h5>
</template>

<script>
export default {
  props: ["title"],
  data: function() {
    return {
      data: null,
    };
  },
  methods: {
    closeChat: function() {
      this.$emit("closeChat");
    },
  },
};
</script>
