<template>
  <div class="h-100">
    <div
      class="chat_tab h-100"
      v-for="(con, index) in ConvertionsData"
      :key="index"
      v-show="con.deleted == false"
      v-bind:class="{
        active: currentConvertion
          ? currentConvertion.convertion_id == con.convertion_id
          : null,
      }"
      :id="con.convertion_id"
    >
      <div class="chat-header-top">
        <ChatTabHeader
          :currentConvertion="currentConvertion"
          @deleteChat="deleteChat"
        />
      </div>
      <div class="chat-messages h-100" ref="chatScroll">
        <div class="message system">
          <p class="text">{{ cryptoText }}</p>
        </div>
        <div class="chat-dynamic-messages">
          <ChatBubble
            v-for="(bubble, index) in CurrentConvertionMessageList"
            :key="index"
            @pushItem="pushItem"
            :keyIndis="index"
            :bubbleData="bubble"
            :user_info="user_info"
            :lang="lang"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatTabHeader from "./ChatTabHeader.vue";
import ChatBubble from "./ChatBubble.vue";
export default {
  props: [
    "ConvertionsData",
    "currentConvertion",
    "CurrentConvertionMessageList",
    "user_info",
    "lang",
  ],
  components: {
    ChatTabHeader,
    ChatBubble,
  },
  data: function() {
    return {
      data: null,
      cryptoText: "",
    };
  },
  watch: {
    CurrentConvertionMessageList: function(newVal, oldVal) {
      this.CurrentConvertionMessageList = newVal;
    },
  },
  methods: {
    pushItem: function(data) {
      this.$emit("pushItem", data);
    },
    deleteChat: function() {
      this.$emit("deleteChat");
    },
    chatScrollTab: function() {
      setTimeout(() => {
        const divs = this.$refs.chatScroll;
        divs.forEach((item) => {
          item.scrollTop = item.scrollHeight;
          item.addEventListener("scroll", (e) => {
            if (e.target.scrollTop == 0) {
              const eDef = e.path[0].childNodes[1].childNodes[0];
              if (eDef) {
                if (eDef.attributes["id"] && eDef.attributes["id"].nodeValue) {
                  const lastMessageId = eDef.attributes["id"].nodeValue;
                  this.$emit("loadMoreMessage", lastMessageId);
                }
              }
            }
          });
        });
      }, 200);
    },
  },
  created() {
    switch (this.lang) {
      case "tr":
        this.cryptoText =
          "Mesajlar ve aramalar uçtan uca şifrelenir. GoWaWe dahil bu sohbetin dışında hiç kimse mesajlarınızı ve aramalarınızı okuyamaz veya dinleyemez.";
        break;
      case "en":
        this.cryptoText =
          "Messages and calls are end-to-end encrypted. No one outside of this chat, including GoWaWe, can read or listen to your messages and calls.";
        break;
    }
  },
};
</script>
