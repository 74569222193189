<template>
    <div>
        <div class="ph-item">
            <div class="ph-col-2">
                <div class="ph-avatar"></div>
            </div>
            <div>
                <div class="ph-row">
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-10 empty"></div>
                </div>
            </div>
        </div>
        <div class="ph-item">
            <div class="ph-col-2">
                <div class="ph-avatar"></div>
            </div>
            <div>
                <div class="ph-row">
                    <div class="ph-col-4"></div>
                    <div class="ph-col-8 empty"></div>
                    <div class="ph-col-6"></div>
                    <div class="ph-col-6 empty"></div>
                    <div class="ph-col-2"></div>
                    <div class="ph-col-10 empty"></div>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
        export default {
            props: [],
            data: function() {
                return  {
                    data:  null
                }
            },
        }
</script>
