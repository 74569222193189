function formatConvertion(
  convertion_id,
  logo,
  name,
  deleted,
  time,
  last_message
) {
  return {
    convertion_id,
    logo,
    name,
    deleted,
    time,
    last_message,
  };
}

export default formatConvertion;
