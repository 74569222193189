<template>
  <div class="action_scene">
    <div class="wrapper_action_close">
      <CloseScene @closeScene="$emit('closeScene')" />
    </div>
    <ImageScene :ImageSceneURL="ImageSceneURL" />
    <div
      class="wrapper_action_message d-flex align-items-center justify-content-center"
    >
      <SendMessageInput
        ref="SendMessageInput"
        :sendMessageDeActive="sendMessageDeActive"
        @sendMessageKey="sendMessageKey"
      />
      <SendMessageButton
        @sendMessageKey="sendMessageKey"
        :sendMessageDeActive="sendMessageDeActive"
      />
    </div>
  </div>
</template>

<script>
import CloseScene from "./Scenes/CloseScene.vue";
import ImageScene from "./Scenes/ImageScene.vue";
import SendMessageInput from "./SendMessageInput.vue";
import SendMessageButton from "./SendMessageButton.vue";

export default {
  props: {
    ImageSceneURL: {
      required: true,
      type: String,
      default: "",
    },
    sendMessageDeActive: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  components: {
    CloseScene,
    ImageScene,
    SendMessageInput,
    SendMessageButton,
  },
  data: function() {
    return {
      data: null,
    };
  },
  methods: {
    sendMessageKey: function() {
      this.$emit("sendMessageKeyChat", this.$refs.SendMessageInput.message);
    },
  },
};
</script>

<style scoped>
.action_scene {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  width: 100%;
  background: #f7f7f7;
  padding: 1em;
}
.wrapper_action_message input {
  width: 100%;
  max-width: 600px;
  display: block;
  border: 1px solid #ccc;
  padding: 0.5em 1em;
  border-radius: 100px;
  outline: none;
  margin-right: 10px;
}
.wrapper_action_close {
  position: relative;
  text-align: right;
}
.wrapper_action_close button {
  width: 25px;
  height: 25px;
  outline: none !important;
  background: transparent;
  border: none;
  cursor: pointer;
}
</style>
