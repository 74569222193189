<template>
  <button
    class="btn d-flex align-items-center justify-content-center"
    :disabled="sendMessageDeActive"
    v-on:click="$emit('sendMessageKey')"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      role="img"
      width="35"
      height="30"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 448 384"
    >
      <path d="M0 384V235l320-43L0 149V0l448 192z" />
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    sendMessageDeActive: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      data: null,
    };
  },
};
</script>

<style scoped>
.btn {
  background: #eab756;
  border-radius: 50%;
  color: #fff;
  position: relative;
  width: 48px;
  height: 48px;
  box-shadow: none !important;
  outline: none !important;
}
.btn svg path {
  fill: #fff;
}
</style>
