<template>
  <div class="ActionBarsList">
    <ul>
      <li>
        <button v-on:click="openFileBrowse()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            width="100%"
            height="100%"
            viewBox="0 0 256 256"
            xml:space="preserve"
          >
            <g transform="translate(128 128) scale(0.72 0.72)" style="">
              <g
                style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)"
              >
                <path
                  d="M 81.683 82.884 H 24.198 c -4.586 0 -8.317 -3.731 -8.317 -8.317 V 35.468 c 0 -4.586 3.731 -8.317 8.317 -8.317 h 57.484 c 4.586 0 8.317 3.731 8.317 8.317 v 39.098 C 90 79.152 86.269 82.884 81.683 82.884 z M 24.198 31.151 c -2.38 0 -4.317 1.937 -4.317 4.317 v 39.098 c 0 2.381 1.937 4.317 4.317 4.317 h 57.484 c 2.381 0 4.317 -1.937 4.317 -4.317 V 35.468 c 0 -2.38 -1.937 -4.317 -4.317 -4.317 H 24.198 z"
                  style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;"
                  transform=" matrix(1 0 0 1 0 0) "
                  stroke-linecap="round"
                />
                <path
                  d="M 70.876 22.527 l -1.166 -10.294 c -0.358 -3.164 -3.214 -5.438 -6.377 -5.08 L 5.117 13.747 c -3.164 0.358 -5.438 3.214 -5.08 6.377 L 4.561 60.07 c 0.358 3.164 3.214 5.438 6.377 5.08 l 0.319 0.037 v -32.39 c 0 -5.672 4.598 -10.27 10.27 -10.27 L 70.876 22.527 z"
                  style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;"
                  transform=" matrix(1 0 0 1 0 0) "
                  stroke-linecap="round"
                />
                <path
                  d="M 88 44.865 L 50.956 62.118 c -1.723 0.802 -3.77 0.339 -4.979 -1.127 l -2.834 -3.436 c -1.228 -1.489 -3.316 -1.941 -5.05 -1.093 l -20.213 9.882 v 8.221 c 0 3.489 2.828 6.317 6.317 6.317 h 57.484 c 3.489 0 6.317 -2.828 6.317 -6.317 V 44.865 z"
                  style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;"
                  transform=" matrix(1 0 0 1 0 0) "
                  stroke-linecap="round"
                />
                <circle
                  cx="51.92"
                  cy="45.22"
                  r="7"
                  style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;"
                  transform="  matrix(1 0 0 1 0 0) "
                />
              </g>
            </g>
          </svg>
        </button>
      </li>
      <li>
        <button>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            width="100%"
            height="100%"
            viewBox="0 0 256 256"
            xml:space="preserve"
          >
            <g transform="translate(128 128) scale(0.72 0.72)" style="">
              <g
                style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)"
              >
                <path
                  d="M 81.039 21.623 h -4.877 c -3.069 0 -5.888 -1.704 -7.335 -4.432 l -1.472 -2.777 c -1.814 -3.421 -5.349 -5.557 -9.197 -5.557 H 31.842 c -3.848 0 -7.383 2.136 -9.197 5.557 l -0.464 0.876 l -1.008 1.901 c -0.983 1.854 -2.6 3.235 -4.499 3.93 c -0.895 0.328 -1.853 0.502 -2.836 0.502 H 8.961 C 4.012 21.623 0 25.668 0 30.657 v 41.452 c 0 4.989 4.012 9.034 8.961 9.034 h 72.079 c 1.835 0 3.541 -0.556 4.962 -1.51 C 88.412 78.014 90 75.249 90 72.109 V 30.657 C 90 25.668 85.988 21.623 81.039 21.623 z M 63.806 57.253 c -1.028 2.588 -2.565 4.914 -4.488 6.852 C 55.654 67.8 50.592 70.085 45 70.085 c -11.183 0 -20.249 -9.14 -20.249 -20.415 c 0 -5.637 2.266 -10.741 5.931 -14.435 c 1.923 -1.938 4.23 -3.489 6.797 -4.525 c 2.325 -0.938 4.863 -1.455 7.521 -1.455 c 11.183 0 20.249 9.14 20.249 20.415 C 65.249 52.35 64.737 54.909 63.806 57.253 z M 77.341 36.522 c -1.914 0 -3.465 -1.564 -3.465 -3.494 c 0 -1.929 1.551 -3.493 3.465 -3.493 c 1.914 0 3.465 1.564 3.465 3.493 C 80.806 34.958 79.255 36.522 77.341 36.522 z"
                  style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;"
                  transform=" matrix(1 0 0 1 0 0) "
                  stroke-linecap="round"
                />
                <path
                  d="M 54.951 53.682 c -0.544 1.369 -1.357 2.6 -2.375 3.626 c -1.939 1.955 -4.617 3.164 -7.576 3.164 c -5.917 0 -10.714 -4.836 -10.714 -10.802 c 0 -2.983 1.199 -5.683 3.138 -7.638 c 1.017 -1.026 2.238 -1.846 3.597 -2.394 c 1.23 -0.497 2.573 -0.77 3.98 -0.77 c 5.917 0 10.714 4.836 10.714 10.802 C 55.714 51.088 55.443 52.442 54.951 53.682 z"
                  style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: #fff; fill-rule: nonzero; opacity: 1;"
                  transform=" matrix(1 0 0 1 0 0) "
                  stroke-linecap="round"
                />
              </g>
            </g>
          </svg>
        </button>
      </li>
    </ul>
    <div class="action_content_file d-none">
      <input type="file" id="file" v-on:change="fileProcess()" />
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      data: null,
      fileInput: null,
    };
  },
  methods: {
    openFileBrowse: function() {
      this.fileInput = document.getElementById("file");
      this.fileInput.click();
    },
    toDataURL: function(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    fileProcess: function() {
      const files = this.fileInput.files[0];
      this.toDataURL(files).then((dataUrl) => {
        this.$emit("openScene", dataUrl);
      });
    },
  },
};
</script>
