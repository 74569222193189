<template>
  <div
    class="message"
    :id="bubbleData._id"
    v-if="bubbleData.deleted_users.length == 0"
    v-bind:class="{
      me: bubbleData.from == user_info.id,
      received: bubbleData.from != user_info.id,
    }"
  >
    <div class="image_preview" v-if="bubbleData.message.image != ''">
      <div
        class="preview_overlay_wrapper"
        v-on:click="
          $emit('pushItem', {
            id: keyIndis,
            data: bubbleData.message.image,
          })
        "
        :style="{ backgroundImage: 'url(' + bubbleData.message.image + ')' }"
      >
        >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          version="1.1"
          viewBox="0 0 256 256"
          xml:space="preserve"
        >
          <g transform="translate(128 128) scale(0.72 0.72)" style="">
            <g
              style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
              transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)"
            >
              <path
                d="M 89.121 84.879 L 63.874 59.632 c 5.55 -6.535 8.583 -14.734 8.583 -23.403 c 0 -9.677 -3.769 -18.775 -10.611 -25.617 C 55.003 3.769 45.905 0 36.229 0 S 17.454 3.769 10.611 10.611 C 3.769 17.454 0 26.552 0 36.229 s 3.769 18.774 10.611 25.617 c 6.842 6.843 15.94 10.611 25.617 10.611 c 8.669 0 16.868 -3.033 23.403 -8.583 l 25.247 25.247 C 85.465 89.707 86.232 90 87 90 s 1.535 -0.293 2.121 -0.879 C 90.293 87.95 90.293 86.05 89.121 84.879 z M 14.854 57.604 c -11.786 -11.786 -11.786 -30.964 0 -42.75 c 5.893 -5.893 13.634 -8.839 21.375 -8.839 s 15.481 2.947 21.375 8.839 c 11.786 11.786 11.786 30.964 0 42.75 C 45.816 69.389 26.64 69.389 14.854 57.604 z"
                style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill-rule: nonzero; opacity: 1;"
                transform=" matrix(1 0 0 1 0 0) "
                stroke-linecap="round"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
    <p class="text">{{ bubbleData.message.text }}</p>
    <p
      class="meta d-flex align-items-center"
      v-bind:class="{ 'justify-content-end': bubbleData.from == user_info.id }"
    >
      <span class="time">{{
        moment(bubbleData.messages_moment)
          .locale(lang)
          .fromNow()
      }}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 18 18"
        width="18"
        height="18"
        class="ml-2"
      >
        <path
          fill="gray"
          d="M17.394 5.035l-.57-.444a.434.434 0 0 0-.609.076l-6.39 8.198a.38.38 0 0 1-.577.039l-.427-.388a.381.381 0 0 0-.578.038l-.451.576a.497.497 0 0 0 .043.645l1.575 1.51a.38.38 0 0 0 .577-.039l7.483-9.602a.436.436 0 0 0-.076-.609zm-4.892 0l-.57-.444a.434.434 0 0 0-.609.076l-6.39 8.198a.38.38 0 0 1-.577.039l-2.614-2.556a.435.435 0 0 0-.614.007l-.505.516a.435.435 0 0 0 .007.614l3.887 3.8a.38.38 0 0 0 .577-.039l7.483-9.602a.435.435 0 0 0-.075-.609z"
        ></path>
      </svg>
    </p>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["bubbleData", "user_info", "lang", "keyIndis"],
  data: function() {
    return {
      data: null,
      moment: moment,
    };
  },
  methods: {},
  mounted() {
    //console.log(this.bubbleData);
  },
};
</script>
