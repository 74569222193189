<template>
  <div class="chatAppMessages">
    <div class="chat-container">
      <Header @closeChat="closeChat" ref="Header" />
      <main class="chat-main">
        <div class="chat-sidebar">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="newChatSvg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            width="1em"
            height="1em"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <g fill="none">
              <path
                d="M12 8v3m0 0v3m0-3h3m-3 0H9"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M14 19c3.771 0 5.657 0 6.828-1.172C22 16.657 22 14.771 22 11c0-3.771 0-5.657-1.172-6.828C19.657 3 17.771 3 14 3h-4C6.229 3 4.343 3 3.172 4.172C2 5.343 2 7.229 2 11c0 3.771 0 5.657 1.172 6.828c.653.654 1.528.943 2.828 1.07"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14 19c-1.236 0-2.598.5-3.841 1.145c-1.998 1.037-2.997 1.556-3.489 1.225c-.492-.33-.399-1.355-.212-3.404L6.5 17.5"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
              />
            </g>
          </svg>
          <div class="chat-sidebar-header">
            <SideBarHeader @openSide="openSide" :user_info="user_info" />
          </div>
          <div class="chat-message-search">
            <MessageSearch @filterConvertions="filterConvertions" />
          </div>
          <div class="users_currentchat_wrapper">
            <ul id="users_currentchat_list">
              <div
                class="ConvertionsPreLoader"
                v-if="ConvertionsActive == false"
              >
                <ConvertionPreLoader />
              </div>
              <div class="ConvertionsList" v-if="ConvertionsActive == true">
                <Convertion
                  :setConvertions="Convertions"
                  :currentConvertion="currentConvertion"
                  :lang="lang"
                  @switchConvertion="switchConvertion"
                />
              </div>
            </ul>
          </div>
          <div
            class="new_chat_side"
            data-id="tab3"
            v-bind:class="{ active: activeTabId == 3 }"
          >
            <ProfileSide @closeChat="closeChat" :user_info="user_info" />
          </div>
          <div
            class="new_chat_side"
            data-id="tab2"
            v-bind:class="{ active: activeTabId == 2 }"
          >
            <ConfigSide @closeChat="closeChat" ref="ConfigSide" />
          </div>
          <div
            class="new_chat_side"
            data-id="tab1"
            v-bind:class="{ active: activeTabId == 1 }"
          >
            <NewChatSide
              @closeChat="closeChat"
              @uSKSet="uSKSet"
              @createNewChat="createNewChat"
              :usersResult="UsersResult"
              :lang="lang"
              ref="NewChatSide"
            />
          </div>
        </div>
        <div class="chat_wrapper">
          <div id="loadingMessage" v-if="messagesActive == false">
            <svg
              class="spinner-container"
              width="65px"
              height="65px"
              viewBox="0 0 52 52"
            >
              <circle
                class="path"
                cx="26px"
                cy="26px"
                r="20px"
                fill="none"
                stroke-width="4px"
              />
            </svg>
          </div>

          <div class="chat_tabs" id="chat_tabs">
            <ChatTabMessages
              :ConvertionsData="Convertions"
              :CurrentConvertionMessageList="CurrentConvertionMessageList"
              :currentConvertion="currentConvertion"
              :user_info="user_info"
              :lang="lang"
              @loadMoreMessage="loadMoreMessage"
              @pushItem="pushItem"
              @deleteChat="deleteChat"
              ref="ChatTabMessages"
            />
          </div>
          <div class="chat-form-container">
            <ActionBars v-if="actionButtons" @openScene="openScene" />
            <SendChat
              ref="sendChat"
              @openAttachment="openAttachment"
              @sendMessageKeyChat="sendMessageKeyChat"
              :sendMessageDeActive="sendMessageDeActive"
            />
          </div>

          <ActionScene
            @closeScene="isActionScene = false"
            :ImageSceneURL="ImageSceneURL"
            @sendMessageKeyChat="sendMessageKeyChat"
            :sendMessageDeActive="sendMessageDeActive"
            v-if="isActionScene"
          />
        </div>
      </main>
    </div>

    <PageLoader />

    <CoolLightBox :items="items" :index="index" @close="index = null" />
  </div>
</template>

<script>
import axios from "axios";
import PageLoader from "../components/PageLoader.vue";
import Header from "../components/Header.vue";
import Convertion from "../components/Convertion.vue";
import ConvertionPreLoader from "../components/ConvertionPreLoader.vue";
import SideBarHeader from "../components/SideBarHeader.vue";
import MessageSearch from "../components/MessageSearch.vue";
import ProfileSide from "../components/ProfileSide.vue";
import ConfigSide from "../components/ConfigSide.vue";
import NewChatSide from "../components/NewChatSide.vue";
import ChatTabMessages from "../components/ChatTabMessages.vue";
import SendChat from "../components/SendChat.vue";
import ActionBars from "../components/ActionBars.vue";
import ActionScene from "../components/ActionScene.vue";
import formatConvertion from "../helpers/convertion";

export default {
  name: "Chat",
  data() {
    return {
      ownConnectId: false,
      items: [],
      index: null,
      Convertions: [],
      Users: [],
      UsersResult: [],
      ConvertionList: [],
      ConvertionMessageList: [],
      CurrentConvertionMessageList: [],
      actionButtons: false,
      ConvertionsActive: false,
      messagesActive: false,
      activeTabId: null,
      user_info: null,
      user_search_key: null,
      sendMessageDeActive: true,
      loadMoreActive: false,
      isActionScene: false,
      ImageSceneURL: "",
      currentConvertion: {
        id: null,
        convertion_id: null,
        name: "",
      },
      lang: "tr",
      clientLocks: {
        getMessageFromServer: {
          convertions: [],
        },
      },
      newMessage: new Audio(
        process.env.VUE_APP_ASSET_URL + "/sounds/new_message.mp3"
      ),
      welcomeUser: new Audio(
        process.env.VUE_APP_ASSET_URL + "/sounds/success.wav"
      ),
    };
  },
  components: {
    ActionScene,
    ActionBars,
    PageLoader,
    Header,
    ConvertionPreLoader,
    Convertion,
    SideBarHeader,
    MessageSearch,
    ProfileSide,
    ConfigSide,
    NewChatSide,
    ChatTabMessages,
    SendChat,
  },
  methods: {
    pushItem: function(data) {
      this.index = data.id;
      if (!this.items.find((item) => item.src == data.image)) {
        this.items.push({
          title: "In nature, nothing is perfect and everything is perfect",
          description: "Photo by Lucas",
          src: data.image,
        });
      }
    },
    openScene: function(data) {
      this.isActionScene = true;
      this.ImageSceneURL = data;
      this.actionButtons = false;
    },
    openAttachment: function() {
      this.actionButtons = !this.actionButtons;
    },
    closeLoader: function() {
      document.getElementById("startup")?.remove();
    },
    openSide: function(id) {
      this.activeTabId = id;
    },
    closeChat: function() {
      this.activeTabId = null;
    },
    filterConvertions: function(data) {
      const filtered = this.ConvertionList.filter(
        (item) => item.name.toUpperCase().indexOf(data.toUpperCase()) !== -1
      );
      this.Convertions = filtered;
    },
    deleteChat: function() {
      if (confirm("Are you sure?")) {
        const deleteChatId = this.currentConvertion.convertion_id;

        this.Convertions.find((item) =>
          item.convertion_id == deleteChatId ? (item.deleted = true) : null
        );

        const mutantData = this.ConvertionMessageList.find((item) =>
          item.convertion_id == deleteChatId
            ? item.messages.filter(
                (ev) => (ev.deleted_users = this.user_info.id)
              )
            : null
        );

        this.CurrentConvertionMessageList = mutantData.messages;

        this.$router.push(
          `/chat/${this.user_info.id}/${this.user_info.convertion_id}`
        );
        this.sendMessageDeActive = true;

        try {
          axios
            .post(process.env.VUE_APP_API_URL + "/bulk-delete", {
              user_id: this.user_info.id.toString(),
              convertion_id: deleteChatId,
            })
            .then((response) => {
              // console.log(response);
            });
        } catch (error) {
          console.log(error);
        }

        // this.$socket.emit("leaveRoom", {
        //   from: this.user_info.id,
        //   convertion_id: deleteChatId,
        // });
      }
    },
    loadMoreMessage: function(id) {
      if (this.loadMoreActive === true && 1 == 2) {
        this.loadMoreActive = false;
        const serverData = {
          convertion_id: this.currentConvertion.convertion_id,
          sender_id: this.user_info.id.toString(),
          id: this.currentConvertion.to.toString(),
          bubble_id: id,
        };

        const fsCon = this.clientLocks.getMessageFromServer.convertions;
        fsCon.splice(fsCon.indexOf(this.currentConvertion.convertion_id), 1);

        this.getMessageFromServer(serverData, false, true);
      }
    },
    createNewChat: function(data) {
      if (data.isNewChat == true) {
        this.closeChat();
        this.$refs.Header.mobileToggle();
        const convertionModule = formatConvertion(
          data.convertion_id,
          data.logo,
          data.name,
          data.time,
          data.last_message
        );

        this.ConvertionList.unshift(convertionModule);

        if (this.$route.params.convertion_id !== data.convertion_id) {
          this.$router.push(`/chat/${this.user_info.id}/${data.convertion_id}`);
        }
        this.Convertions = this.ConvertionList;
        // this.sendMessageDeActive = false;

        let isConvertionHas = this.ConvertionMessageList.filter(
          (item) => item.convertion_id === data.convertion_id
        );

        if (isConvertionHas.length < 1) {
          const messageObjectNew = {
            convertion_id: data.convertion_id,
            messages: [],
          };
          this.ConvertionMessageList.push(messageObjectNew);
        }
        this.UsersResult = [];
        this.switchConvertion(data, true);
      }
    },
    receivedMessageCreateChat: function(data) {
      if (
        (data.from != this.user_info.id && data.to == this.user_info.id) ||
        (data.from == this.user_info.id && data.to != this.currentConvertion.to)
      ) {
        // console.log("received Message :" + this.user_info.id);

        let isConvertionHas = this.ConvertionMessageList.filter(
          (item) => item.convertion_id === data.convertion_id
        );

        if (isConvertionHas.length < 1) {
          const convertionModule = formatConvertion(
            data.convertion_id,
            data.logo,
            data.name,
            false,
            data.time,
            data.message.text.length > 14
              ? data.message.text.split(0, 14)
              : data.message.text
          );

          if (this.Convertions.length < 1) {
            this.currentConvertion = data;
          }

          this.ConvertionList.push(convertionModule);

          this.Convertions = this.ConvertionList;
          //   this.sendMessageDeActive = false;

          const messageObjectNew = {
            convertion_id: data.convertion_id,
            messages: [],
          };
          this.ConvertionMessageList.push(messageObjectNew);
        }
      }
    },
    getMessageFromServer: function(data, setPush = false, loadMore = false) {
      const fromConvertion = data.convertion_id;

      if (
        this.clientLocks.getMessageFromServer.convertions.indexOf(
          fromConvertion
        ) === -1
      ) {
        this.clientLocks.getMessageFromServer.convertions.push(fromConvertion);

        const mData = {
          from: data.sender_id ? data.sender_id : this.user_info.id,
          to: data.id,
          convertion_id: data.convertion_id,
          id: data.bubble_id ? data.bubble_id : null,
        };
        this.$socket.emit("getMessages", mData);

        this.sockets.subscribe("setMessages", (dataMessage) => {
          if (dataMessage) {
            if (loadMore == true) {
              dataMessage.forEach((item) => {
                this.listChatMessages(fromConvertion, item, loadMore);
              });
            } else {
              dataMessage.reverse().forEach((item) => {
                this.listChatMessages(fromConvertion, item, loadMore);
              });
            }
          }
          if (loadMore == false) {
            this.$refs.ChatTabMessages.chatScrollTab();
          }

          if (setPush) {
            if (this.Convertions.length == 1) {
              this.currentConvertion = {
                convertion_id: data.convertion_id,
                from: data.sender_id ? data.sender_id : this.user_info.id,
                to: data.id,
                logo: data.logo,
                name: data.name,
                time: data.time ? data.time : null,
              };
            }
          }

          setTimeout(() => {
            this.loadMoreActive = true;
          }, 1000);

          this.messagesActive = true;
        });

        this.messagesActive = true;
      }
    },
    switchConvertion: function(item, isNew = false) {
      if (item.convertion_id === this.currentConvertion.convertion_id) {
        return false;
      }

      this.sendMessageDeActive = false;

      this.currentConvertion = {
        convertion_id: item.convertion_id ? item.convertion_id : null,
        from: item.sender_id ? item.sender_id : this.user_info.id,
        to: item.id,
        logo: item.logo,
        deleted: false,
        name: item.name,
        time: item.time ? item.time : null,
      };

      this.connectSocket(item.convertion_id);

      this.Users.find((el, indis) =>
        el.id == item.id ? this.Users.splice(indis, 1) : null
      );

      this.$refs.NewChatSide.clearValue();

      if (isNew == false) {
        if (
          this.ConvertionMessageList.find(
            (el) => el.convertion_id == item.convertion_id
          ).messages.length == 0
        ) {
          this.messagesActive = false;
          this.getMessageFromServer(item, false, false);
          //  console.log("getMessageFromServer");
        } else {
          this.listChatMessages(item.convertion_id, null, false);
        }
      }

      if (this.$route.params.convertion_id !== item.convertion_id) {
        this.$router.push(`/chat/${this.user_info.id}/${item.convertion_id}`);
      }
      this.$refs.ChatTabMessages.chatScrollTab();
      // this.sendMessageDeActive = false;

      this.CurrentConvertionMessageList = this.ConvertionMessageList.find(
        (item) => item.convertion_id == this.currentConvertion.convertion_id
      ).messages;

      this.messagesActive = true;
    },
    connectSocket: function(convertion_id = null) {
      const user_id = this.user_info.id;
      let user_room =
        convertion_id !== null
          ? convertion_id
          : this.$route.params.convertion_id;

      if (this.ownConnectId == false) {
        this.ownConnectId = true;
        this.$socket.emit("joinRoom", {
          from: user_id,
          convertion_id: this.user_info.convertion_id,
        });
      }
      this.$socket.emit("joinRoom", {
        from: user_id,
        convertion_id: user_room,
      });
    },
    getConvertions: async function() {
      this.$socket.emit("getConvertions", {
        id: this.user_info.id,
        static_id_me: this.user_info.convertion_id,
        convertion_id: this.currentConvertion.convertion_id,
      });
      this.closeLoader();
    },
    pushConvertionGlobal: function(cItem, messageObjectNew) {
      this.ConvertionMessageList.push(messageObjectNew);
      this.ConvertionList.push(cItem);
      this.Convertions = this.ConvertionList;
      const urlConvertionId = this.$route.params.convertion_id;
      if (this.currentConvertion) {
        if (
          urlConvertionId == this.user_info.convertion_id ||
          urlConvertionId == "undefined"
        ) {
          this.sendMessageDeActive = true;
        } else {
          this.sendMessageDeActive = false;
        }
      }
      this.ConvertionsActive = true;

      this.Convertions.find((item) => this.connectSocket(item.convertion_id));
      this.Convertions.find((item) =>
        item.convertion_id == urlConvertionId
          ? (this.currentConvertion.convertion_id = item.convertion_id) &&
            (this.currentConvertion.to = item.id) &&
            this.getMessageFromServer(item, true, false)
          : null
      );
    },
    setConvertions: function(data) {
      console.log(data);
      const fetchData = new Promise(function(resolve, reject) {
        resolve(data);
        reject(err);
      });

      const th = this;

      fetchData.then(
        function(data) {
          if (data.convertion_id !== undefined) {
            const cItem = {
              convertion_id: data.convertion_id,
              id: data.sender_id == th.user_info.id ? data.id : data.sender_id,
              logo: data.logo,
              name: data.name,
              deleted: data.deleted,
              last_message: data.last_message,
              time: data.last_message_time,
            };

            const messageObjectNew = {
              convertion_id: data.convertion_id,
              messages: [],
            };
            th.pushConvertionGlobal(cItem, messageObjectNew);
          }

          if (th.currentConvertion.convertion_id) {
            th.sendMessageDeActive = false;
          }
          th.ConvertionsActive = true;
          th.messagesActive = true;
        },
        function(err) {
          console.log(err);
        }
      );
    },
    uSKSet: function(svalue) {
      if (svalue && svalue.length > 2) {
        this.UsersResult = this.Users.filter(
          (item) =>
            (item.memberPhone &&
              item.memberPhone != "" &&
              item.memberPhone === svalue) ||
            (item.memberEmail &&
              item.memberEmail != "" &&
              item.memberEmail === svalue) ||
            (item.storeEmail &&
              item.storeEmail != "" &&
              item.storeEmail === svalue) ||
            (item.storePhone &&
              item.storePhone != "" &&
              item.storePhone === svalue) ||
            (item.name &&
              item.name.toUpperCase().indexOf(svalue.toUpperCase()) > -1)
        );
      } else {
        this.UsersResult = [];
      }
    },
    listChatMessages: function(convertion_id, data = null, loadMore = false) {
      this.Convertions.find((item) =>
        item.convertion_id == convertion_id ? (item.deleted = false) : null
      );

      this.sendMessageDeActive = false;

      if (data && data !== null && data !== undefined) {
        if (this.Convertions.length < 2) {
          if (
            this.$route.params.convertion_id !== data.convertion_id &&
            data.convertion_id
          ) {
            this.$router.push(
              `/chat/${this.user_info.id}/${
                data.convertion_id !== undefined ? data.convertion_id : null
              }`
            );
          } else if (
            this.$route.params.convertion_id !== data.convertion_id &&
            data.convertion_id
          ) {
            this.$router.push(
              `/chat/${this.user_info.id}/${this.currentConvertion.convertion_id}`
            );
          }
        }
      }

      let currentConvertionData = this.ConvertionMessageList.find(
        (item) => item.convertion_id === convertion_id
      );

      if (data && currentConvertionData !== undefined) {
        if (
          (data.to && data.to === this.user_info.id.toString()) ||
          data.from === this.user_info.id.toString()
        ) {
          if (loadMore == true) {
            // if (this.ConvertionMessageList.length > 0) {
            //   this.ConvertionMessageList.find((item) =>
            //     item.convertion_id === data.convertion_id
            //       ? item.messages.length > 0
            //         ? item.messages.find(
            //             (msg) =>
            //               msg.convertion_id !== data.convertion_id &&
            //               msg._id.toString() !== data._id.toString()
            //           )
            //           ? item.messages.unshift(data)
            //           : null
            //         : item.messages.unshift(data)
            //       : null
            //   );
            // }
          } else {
            if (this.ConvertionMessageList.length > 0) {
              this.ConvertionMessageList.find((item) =>
                item.convertion_id === data.convertion_id
                  ? data._id
                    ? item.messages.unshift(data)
                    : item.messages.push(data)
                  : null
              );
            }
            // currentConvertionData.messages.push(data);
          }
        }

        if (
          this.Convertions.filter((x) => x.convertion_id === convertion_id)
            .length > 0 &&
          data.to &&
          data.to === this.user_info.id.toString()
        ) {
          if (this.$refs.ConfigSide.soundmode) {
            if (this.loadMoreActive && this.newMessage) {
              this.newMessage.play();
            }
          }
        }
      }

      const currentChatObject = this.ConvertionMessageList.find(
        (item) => item.convertion_id == this.currentConvertion.convertion_id
      );

      if (currentChatObject !== undefined) {
        this.CurrentConvertionMessageList = this.ConvertionMessageList.find(
          (item) => item.convertion_id == this.currentConvertion.convertion_id
        ).messages;
      }
    },
    sendMessageKeyChat: function(messageText) {
      this.isActionScene = false;
      if (this.ImageSceneURL == "") {
        if (messageText.trim() === "" || !messageText) {
          return false;
        } else {
          this.sendMessage("", messageText);
        }
      } else {
        this.sendMessage(this.ImageSceneURL, messageText);
      }
    },
    sendMessage: function(image = "", messageText) {
      if (!this.currentConvertion.to || !this.currentConvertion.from) {
        alert("Please Select a Convertion!!");
        return false;
      }

      console.log(this.currentConvertion);

      let dataTo =
        this.currentConvertion.to == this.user_info.id
          ? this.currentConvertion.from
          : this.currentConvertion.to;

      const messageObject = {
        name: this.user_info.name,
        logo: this.user_info.logo,
        message: {
          text: messageText,
          image: image,
        },
        static_id_from: this.user_info.convertion_id,
        static_id_to: this.currentConvertion.convertion_id,
        deleted_users: [],
        from: this.user_info.id.toString(),
        to: typeof dataTo == "string" ? dataTo : dataTo.toString(),
        convertion_id: this.currentConvertion.convertion_id,
        time: new Date().getTime(),
      };

      this.$socket.emit("chatMessage", messageObject);
      this.$refs.sendChat.clearText();
      this.$refs.ChatTabMessages.chatScrollTab();
    },
    getMessageSubscribe: function() {
      this.sockets.subscribe("getConvertionsData", (data) => {
        const myId = this.user_info.id.toString();

        if (data.length > 0) {
          data.forEach((dataItem) => {
            const toOrFrom =
              dataItem.from === myId
                ? dataItem.static_id_to.trim()
                : dataItem.static_id_from.trim();

            const findData = this.Users.find(
              (item) => item.convertion_id == toOrFrom
            );

            if (findData) {
              const setConvertionData = {
                convertion_id: dataItem.convertion_id,
                id: parseInt(dataItem.to),
                logo: findData.logo,
                name: findData.name,
                deleted: false,
                last_message: "",
                time: "",
                sender_id: parseInt(dataItem.from),
              };

              // currentConvertionData

              this.setConvertions(setConvertionData);
            } else {
              this.ConvertionsActive = true;
              this.messagesActive = true;
            }
          });
        } else {
          this.ConvertionsActive = true;
          this.loadMoreActive = true;
          this.messagesActive = true;
        }
      });

      this.sockets.subscribe("getMessage", (data) => {
        this.receivedMessageCreateChat(data);
        this.listChatMessages(data.convertion_id, data, false);
        this.$refs.ChatTabMessages.chatScrollTab();
      });

      this.sockets.subscribe("changeRoomForToGet", (xdata) => {
        if (
          xdata.from === this.user_info.id.toString() ||
          xdata.to === this.user_info.id.toString()
        ) {
          if (this.$route.params.convertion_id !== xdata.convertion_id) {
            this.Convertions.find((c) =>
              c.convertion_id == xdata.redirect_id
                ? (c.convertion_id = xdata.convertion_id) && (c.id = xdata.from)
                : null
            );

            this.ConvertionMessageList.find((item) =>
              item.convertion_id == xdata.redirect_id
                ? (item.convertion_id = xdata.convertion_id) &&
                  item.messages.find(
                    (m) => (m.convertion_id = xdata.convertion_id)
                  )
                : null
            );

            if (
              this.ConvertionMessageList.find(
                (item) => item.convertion_id === xdata.convertion_id
              ) !== undefined
            ) {
              if (xdata.from === this.user_info.id.toString()) {
                this.$router.push(
                  `/chat/${this.user_info.id}/${xdata.convertion_id}`
                );

                this.currentConvertion.convertion_id = xdata.convertion_id;
                // console.log(this.currentConvertion);
              } else if (this.Convertions.length < 2) {
                if (this.user_info.id.toString() === xdata.to) {
                  this.$router.push(
                    `/chat/${this.user_info.id}/${xdata.convertion_id}`
                  );
                  this.currentConvertion = {
                    convertion_id: xdata.convertion_id,
                    from: this.user_info.id.toString(),
                    to: xdata.from.toString(),
                    name: this.user_info.name,
                    logo: this.user_info.logo,
                  };
                }
              }

              if (this.Convertions.length < 2) {
                this.connectSocket(xdata.convertion_id);
                this.listChatMessages(xdata.convertion_id);

                this.$refs.ChatTabMessages.chatScrollTab();
              }
            }
          }
        }
      });
    },

    connectDirectly: function() {
      if (this.$route.params.convertion_id !== this.user_info.convertion_id) {
        const nitem = this.Users.find(
          (item) => item.convertion_id == this.$route.params.convertion_id
        );
        if (nitem) {
          this.createNewChat(nitem);
        }
      }
    },
    getUsers: function() {
      try {
        axios.get("https://gowawe.com/api/chatstorelist").then((response) => {
          this.Users = response.data.data;
          this.getUser();
        });
      } catch (error) {
        console.log(error);
      }
    },
    getUser: function() {
      try {
        axios
          .get(
            process.env.VUE_APP_API_URL + "/get-users/" + this.$route.params.id
          )
          .then((response) => {
            if (response.data) {
              this.user_info = response.data;
              this.connectSocket();
              this.getConvertions();
              this.connectDirectly();
            } else {
              window.location.href = "https://gowawe.com/";
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  sockets: {
    setMessages(data) {
      // data
    },
    getConvertionsData(data) {
      // data
    },
    getMessage(data) {
      //console.log(data);
    },
    changeRoomForToGet(data) {
      // data
    },
  },
  created() {
    const userLang = navigator.language || navigator.userLanguage;
    this.lang = userLang.split("-")[0];
  },
  mounted() {
    this.getUsers();
    this.closeLoader();
    this.getMessageSubscribe();
    if (this.$refs.ConfigSide.soundmode) {
      this.welcomeUser.play();
    }

    const th = this;
    document.addEventListener("keydown", function(e) {
      if (!e) e = window.event;
      if (e.key === "Escape") {
        th.closeChat();
      }
    });
  },
  computed: {
    //...mapGetters("auth", ["user"])
  },
};
</script>
